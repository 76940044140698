(function (){
    function businessHours() {
        var h = document.querySelector('.business-hours');
        if (!h) return;

        // Get JSON object from BLOX business.
        h = JSON.parse(h.dataset.hoursTmp);

        var statusEl = document.getElementById('business-status');
        var span = document.createElement('span');

        // Determine if business is open or closed.
        if (h.open && h.close) {
            // Get client date and time.
            var d = new Date();
            var aMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            var year = d.getFullYear();
            var month = aMonths[d.getMonth()];
            var day = d.getDate();
            var time = d.getTime();

            // Get open and close date objects.
            var open = new Date(month + ' ' + day + ', ' + year + ' ' + h.open);
            var close = new Date(month + ' ' + day + ', ' + year + ' ' + h.close);

            // Check if close is before open and if true, set close to tomorrow
            if (close < open) {
                close.setDate(close.getDate() + 1);
            }

            // Convert open and close to timestamps.
            open = open.getTime();
            close = close.getTime();

            // Determine if open or closed.
            if (open <= time && time <= close) {
                span.classList.add('text-success');
                span.textContent = 'Open today until ' + h.close.replace(':00', '');
                statusEl.append(span);
            } else {
                span.classList.add('text-danger');
                span.textContent = 'Closed';
                statusEl.append(span);
            }
        } else if (h.status) {
            if (h.status == 'closed') {
                span.classList.add('text-danger');
                span.textContent = 'Closed';
                statusEl.append(span);
            } else if(h.status == 'appt') {
                span.classList.add('text-info');
                span.textContent = 'Call us for an appointment';
                statusEl.append(span);
            } else if(h.status == "24") {
                span.classList.add('text-success');
                span.textContent = 'Open 24 hours a day';
                statusEl.append(span);
            }
        }
    }

    if (document.readyState == 'loading') {
        document.addEventListener('DOMContentLoaded', function() {
            businessHours();
        });
    } else {
        businessHours();
    }
})();